import { template as template_91ce315f06754236b355a835f32bd239 } from "@ember/template-compiler";
const FKLabel = template_91ce315f06754236b355a835f32bd239(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
